import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import PageTitle from 'components/PageTitle';
import { Alert, Button, Card, Col, ProgressBar, Row, Toast, ToastContainer } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Case,
    CaseLocation,
    SelectJob,
    SelectJobStatus
} from "helpers/interfaces";
import CaseApi from "api/case-api";
import { getDisplayDate, loading } from "helpers/utils";
import classNames from "classnames";
import SelectApi from "api/select-api";
import CameraMediaApi from "api/camera-media-api";
import { ConfirmDialogBox } from "components/ConfirmDialogBox";
import SelectImages from "./SelectImages";
import HDImages from "./HDImages";
import Videos from "./Videos";
import AllImages from "./AllImages";
import { GalleryItem } from "helpers/types";
import { HDRequestStatus } from "helpers/interfaces";
import { useDebounce } from "helpers/hooks";

const caseApi = new CaseApi();
const selectApi = new SelectApi()

type BannerNotification = {
    text: string;
    variant: string;
    [key: string]: any
}

type ToastNotification = {
    message: string;
    [key: string]: any
}

const DefaultSelectJob: any = {
    status: SelectJobStatus.CREATED,
    capture_date: "",
    location_id: 0,
    progress: 0,
    regions_of_interest: [],
    select_image_ids: [],
    threshold: 0
}

type ICaseImagesContext = {} & {
    selectImageIds: number[];
    lockedImageIds: number[];
    selectJob: SelectJob | any;
    caseInfo: Case | any,
    locationInfo: CaseLocation | any
    captureDate: string;
    onShowToastMessage?: (...args: any) => void;
    [key: string]: any
}
export const CaseImagesContext = createContext<ICaseImagesContext>({
    captureDate: "",
    caseInfo: null,
    locationInfo: null,
    selectImageIds: [],
    lockedImageIds: [],
    selectJob: null
});

const CaseImages = () => {
    const [selectedCase, setSelectedCase] = useState<Case | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();
    const caseId = Number(params.caseId);
    const locationId = Number(params.locationId);
    const captureDate: any = params.captureDate;
    const navigate = useNavigate();
    const [thresholdValue, setThresholdValue] = useState(0);
    const [galleryName, setGalleryName] = useState<string>('all');
    const [showAutoSelectConfirmation, setShowAutoSelectConfirmation] = useState(false);
    const [isCreatingSelectJob, setIsCreatingSelectJob] = useState(false);
    const [selectJob, setSelectJob] = useState<SelectJob | null>(null);
    const [startPollingSelectJob, setStartPollingSelectJob] = useState(false)
    const [selectJobPoll, setSelectJobPoll] = useState<any>();
    const [bannerNotifications, setBannerNotifications] = useState<{ [key: string]: BannerNotification }>({})
    const [locationInfo, setLocationInfo] = useState<CaseLocation | null>(null)
    const [toastMessages, setToastMessages] = useState<ToastNotification[]>([])
    const [showCancelJobConfirmation, setShowCancelJobConfirmation] = useState(false);
    const [isCancellingSelectJob, setIsCancellingSelectJob] = useState(false);
    const contextMenuContainer = useRef(null);
    const [selectImageIds, setSelectImageIds] = useState<number[]>([]);
    const [lockedImageIds, setLockedImageIds] = useState<number[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(48);
    const [currentViewImageIndex, setCurrentViewImageIndex] = useState(0);
    const [roiPopupImage, setRoiPopupImage] = useState<any>();
    const [showRoiModal, setShowRoiModal] = useState(false);
    const [images, setImages] = useState<GalleryItem[]>([]);
    const [imageSequenceNumberToShowInPopup, setImageSequenceNumberToShowInPopup] = useState<Number>(-1);
    const [showGoToImageLoadingAnim, setShowGoToImageLoadingAnim] = useState(false)
    
    

    const mediaApi = new CameraMediaApi();
    
    if (!captureDate) {
        navigate(`/cases/${caseId}/locations/${locationId}`)
    }

    const showNotificationBanner = useCallback((message: string, variant: string) => {
        setBannerNotifications(prevState => {
            const key = (Math.random() + 1).toString(36)
            const msg = {
                variant: variant,
                text: message
            }
            return {...prevState, [key]: msg}
        })
    }, []);

    const getImageSrc = (galleryIndex: number) => {
        //console.log('In getImageSrc func.');
        //console.log('In getImageSrc func. galleryIndex is ' + galleryIndex);
        return images[galleryIndex]?.image_src;

    }

    const goToImageDeterminePageNumber = async (target_image_seq_number: number) => {
        let page_num = null
        let resp = await caseApi.getFrontendPageForTargetImage(caseId, locationId, target_image_seq_number, {
            capture_date: captureDate,
            page_size: pageSize,
        })
        if (resp.go_to_page) {
            page_num = resp.go_to_page
        }
        return page_num
    }

    const goToPageWithTargetImage = async (target_image_seq_number: number, switchToAllImagesGallery: boolean = false ) => {

        let page_num = await goToImageDeterminePageNumber(target_image_seq_number)

        if (page_num) {
            if (switchToAllImagesGallery === true) {
                setGalleryName('all')
            }
            setCurrentPage(page_num)
            setImageSequenceNumberToShowInPopup(target_image_seq_number)
        } else {
            showToastMessage && showToastMessage('Could not find image ' + target_image_seq_number)
            setShowGoToImageLoadingAnim(false)
            return;
        }
    }

    const debounce = useDebounce();
    const onGoToImage = debounce((sequence: string) => {
        if (sequence.length < 1 || sequence.trim().length < 1) {
            return
        }
        let sequence_number_as_int = parseInt(sequence)
        if ( Number.isNaN(sequence_number_as_int) ) {
            showToastMessage('Image number is invalid.')
            return
        }

        setShowGoToImageLoadingAnim(true)

        if (galleryName !== 'all') {
            goToPageWithTargetImage(sequence_number_as_int, true)
            return;
        }


        //check if image exists on this page
        for( let i = 0; i < images.length; i++ ) {
            if (parseInt(images[i].sequence) === sequence_number_as_int) {
                openLightBox(i, images[i].image_src)
                setShowGoToImageLoadingAnim(false)
                return
            }
        }

        goToPageWithTargetImage(sequence_number_as_int)
    }, 1500 )


    // Fetch Case
    useEffect(() => {
        const fetchCaseInfo = async () => {
            setIsLoading(true);
            try {
                const _case = await caseApi.fetchCase(caseId)
                setSelectedCase(_case);
            } catch (e) {
                console.log("Error fetching data", e);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCaseInfo();
    }, [caseId]);

    // Fetch Location info
    useEffect(() => {
        const fetchLocationInfo = async () => {
            try {
                setIsLoading(true);
                const location = await caseApi.getLocation(caseId, locationId)
                setLocationInfo(location);

            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false)
            }
        };

        fetchLocationInfo();
    }, [caseId, locationId]);

    const fetchLatestSelectJob = useCallback(async () => {
        try {
            let selectJob = await selectApi.getLatestSelectJob(locationId, captureDate);
            if (Object.keys(selectJob).length === 0) {
                selectJob = DefaultSelectJob as SelectJob;
            }
            setSelectJob(selectJob)
            const selectIds = selectJob ? selectJob.select_image_ids : [];
            setSelectImageIds(selectIds)
            const threshold = Math.floor(selectJob.threshold / 255 * 100)
            setThresholdValue(threshold)

            // Start/Stop poll when status changes
            if ([SelectJobStatus.STARTED, SelectJobStatus.IN_PROGRESS].includes(selectJob.status)) {
                setStartPollingSelectJob(true);
            } else {
                setStartPollingSelectJob(false);
            }
        } catch (e) {
            console.error(e);
        }
    }, [captureDate, locationId]);

    useEffect(() => {
        fetchLatestSelectJob();
    }, [fetchLatestSelectJob]);


    // Fetch locked images
    useEffect(() => {
        const fetchLockedImages = async () => {
            try {
                setIsLoading(true);
                let lockedImages = await mediaApi.getLockedMediaItems(locationId, captureDate );
                setLockedImageIds(lockedImages.locked_image_ids);
            } catch (e) {
                console.log('there seems to be an error with fetching locked images.');
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
        fetchLockedImages();
    }, [locationId, captureDate]);

    // Start/Stop select job poll
    useEffect(() => {
        if (!selectJob) {
            return;
        }
        // Start polling
        if (!selectJobPoll && startPollingSelectJob) {
            const interval = setInterval(() => fetchLatestSelectJob(), 3000) // Poll every 3 seconds
            setSelectJobPoll(interval);
            return;
        }
        // Stop polling
        if (selectJobPoll && !startPollingSelectJob) {
            clearInterval(selectJobPoll);
            setSelectJobPoll(null);

            if (selectJob.status === SelectJobStatus.COMPLETED) {
                const message = `Auto select job completed with ${selectJob.select_image_ids.length} selections`;
                showNotificationBanner(message, "success")
            }
            if (selectJob.status === SelectJobStatus.FAILED) {
                const message = "Auto select job failed.";
                showNotificationBanner(message, "danger")
            }
        }

    }, [startPollingSelectJob, selectJobPoll, selectJob, fetchLatestSelectJob, showNotificationBanner]);

    const onThresholdValueChanged = (e: any) => {
        setThresholdValue(e.target.value);
    }

    const createAutoSelectJob = async () => {
        try {
            setIsCreatingSelectJob(true);
            const selectJob = await selectApi.createSelectJob({
                location_id: locationId,
                capture_date: captureDate,
                threshold: thresholdValue
            });
            setSelectJob(selectJob);
            setStartPollingSelectJob(true);
            const message = "Auto select job has been started"
            showNotificationBanner(message, "info")
        } catch (e: any) {
            console.log(e)
            const msg = e?.data?.error || "An error occurred while performing action"
            showNotificationBanner(msg, "danger")
        } finally {
            setIsCreatingSelectJob(false);
            toggleAutoSelectConfirmation();
        }
    }

    const toggleAutoSelectConfirmation = () => {
        setIsCreatingSelectJob(false);
        setShowAutoSelectConfirmation(!showAutoSelectConfirmation);
    }

    const onCloseNotification = (key: string) => {
        setBannerNotifications(prevState => {
            delete prevState[key]
            return prevState
        });
    }

    const createManualSelectJob = async (imageIds: number[]) => {
        try {
            setIsCreatingSelectJob(true);
            const job = await selectApi.createSelectJob({
                location_id: locationId,
                capture_date: captureDate,
                select_type: "manual",
                select_image_ids: imageIds
            });
            setSelectJob(job);
            const message = "Select saved."
            showNotificationBanner(message, "info")
        } catch (e) {
            console.log(e);
        } finally {
            setIsCreatingSelectJob(false);
        }
    }

    const onCloseToast = (index: number) => {
        const list = [...toastMessages];
        list.splice(index, 1);
        setToastMessages(list);
    };

    const cancelSelectJob = async () => {
        try {
            setIsCancellingSelectJob(true);
            const job = await selectApi.cancelSelectJob(selectJob?.id);
            setSelectJob(job)
            // Cancel polling
            setStartPollingSelectJob(false);
            setShowCancelJobConfirmation(false);

            const message = "Auto select job has been cancelled";
            showNotificationBanner(message, "danger");
        } catch (e) {
            console.error("Error cancelling auto select")
        } finally {
            setIsCancellingSelectJob(false);
        }
    }

    const showToastMessage = useCallback((message: string, type = "warning") => {
        setToastMessages(prevState => {
            const msg: ToastNotification = {
                message: message,
                type: type
            }
            const newMessages = [...prevState, msg];
            return newMessages;
        });
    }, []);

    const getImageGallery = (name: string) => {
        switch (name) {
            case 'all':
                return <AllImages/>;
            case 'select':
                return <SelectImages/>;
            case 'hd':
                return <HDImages/>;
            case 'video':
                return <Videos/>;
        }
    }

    const imageRequestHD = async (
        index: number, 
        imageId: number, 
        paramImages: GalleryItem[], 
        paramSetImages: (arg1: GalleryItem[]) => void, 
        paramMediaApi: CameraMediaApi
    ) => {

        const image = paramImages[index]
        if (image.hd_request_status === HDRequestStatus.PENDING || image.hd_file_path !== null) {
            showToastMessage && showToastMessage('HD already requested')
            return;
        }
        if (image.hd_request_status === HDRequestStatus.COMPLETED) {
            showToastMessage && showToastMessage('HD received', "info")
            return;
        }
        try {
            const hdRequest = await paramMediaApi.requestHDImage(imageId)
            image.hd_request_status = hdRequest.status;
            image.hd_file_path = hdRequest.filepath;
            paramImages[index] = image
            paramSetImages([...paramImages])
        } catch (e) {
            console.log(e);
        }
    }


    const onHideRoiModal = () => {
        setRoiPopupImage(null);
        setShowRoiModal(false);
    }

    const openLightBox = (index: number, imageSrc: string) => {
        setCurrentViewImageIndex(index);
        onShowRoiModal(imageSrc)
    }

    const onShowRoiModal = (imageSrc?: string) => {
        setRoiPopupImage(imageSrc)
        setShowRoiModal(true);
    };


    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    {label: "Cases", path: "/cases"},
                    {label: "View Cases", path: "/cases"},
                    {label: selectedCase?.name, path: `/cases/${caseId}`},
                    {label: `${locationInfo?.name}`, path: `/cases/${caseId}/locations/${locationId}`},
                    {
                        label: getDisplayDate(captureDate),
                        path: `/cases/${caseId}/locations/${locationId}/${captureDate}`,
                        active: true
                    }
                ]}
                title={getDisplayDate(captureDate)}
            />
            {Object.keys(bannerNotifications).length > 0 &&
                <Row>
                    <Col>
                        {Object.entries(bannerNotifications).map(([itemKey, item], idx) => {
                            return (
                                <Alert
                                    key={idx}
                                    variant={item.variant || "primary"}
                                    onClose={() => onCloseNotification(itemKey)}
                                    dismissible>
                                    {item.text}
                                </Alert>
                            )
                        })
                        }
                    </Col>
                </Row>
            }

            <Card>
                <Card.Body>
                    {isLoading && loading()}
                    <Row>
                        <Col md={6} >
                            <div className="gallery-filter-menu">
                                <Link
                                    to="#"
                                    className={classNames('filter-menu-item', 'me-1', {
                                        active: galleryName === 'all',
                                    })}
                                    onClick={() => setGalleryName('all')}> View All
                                </Link>
                                <Link
                                    to="#"
                                    className={classNames('filter-menu-item', 'me-1', {
                                        active: galleryName === 'select',
                                    })}
                                    onClick={() => setGalleryName('select')}>Select Images
                                </Link>
                                <Link
                                    to="#"
                                    className={classNames('filter-menu-item', 'me-1', {
                                        active: galleryName === 'hd',
                                    })}
                                    onClick={() => setGalleryName('hd')}>HD
                                </Link>
                                <Link
                                    to="#"
                                    className={classNames('filter-menu-item', 'me-1', {
                                        active: galleryName === 'video',
                                    })}
                                    onClick={() => setGalleryName('video')}>Video
                                </Link>
                                {(isCreatingSelectJob ||
                                        selectImageIds.length !== selectJob?.select_image_ids.length
                                    ) &&
                                    <Button
                                        disabled={isCreatingSelectJob}
                                        onClick={() => createManualSelectJob(selectImageIds)}
                                        variant={"secondary"}>
                                        {isCreatingSelectJob && <div
                                            className="spinner-border spinner-border-sm me-1"/>}
                                        Save Selected Images
                                    </Button>
                                }
                                <span ref={contextMenuContainer}></span>
                            </div>
                        </Col>
                        <Col md={6} >
                            <div className="d-flex align-items-center justify-content-end gap-2">
                                {
                                    !selectJob || ![SelectJobStatus.STARTED, SelectJobStatus.IN_PROGRESS].includes(selectJob?.status) ? (
                                            <>
                                                <div className="d-flex align-items-center gap-2">
                                                    <span className="form-label">Threshold</span>
                                                    <input
                                                        type="range"
                                                        className="form-range d-inline"
                                                        min="0"
                                                        max="100"
                                                        step={2}
                                                        value={thresholdValue}
                                                        onChange={onThresholdValueChanged}
                                                        id="threshold"/>
                                                    <span>{thresholdValue}</span>
                                                </div>
                                                <Button
                                                    onClick={toggleAutoSelectConfirmation}
                                                    variant={"primary"}>Auto Select Images
                                                </Button>
                                            </>) :
                                        <div className="d-flex align-items-center gap-2">
                                            <span
                                                className="text-primary ">In progress</span>
                                            <div className='progress-bar'>
                                                {(selectJob?.progress) > -1 && <ProgressBar
                                                    className={"select-progress"}
                                                    striped variant="primary"
                                                    now={selectJob?.progress}
                                                    label={`${selectJob?.progress}%`} animated={true}/>}
                                            </div>
                                            <Button
                                                onClick={() => setShowCancelJobConfirmation(true)}
                                                variant={"secondary"}>Cancel
                                            </Button>
                                        </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {selectedCase && locationInfo && captureDate &&
                <CaseImagesContext.Provider
                    value={{
                        selectImageIds,
                        lockedImageIds,
                        setSelectImageIds,
                        setLockedImageIds,
                        setLocationInfo,
                        selectJob,
                        captureDate,
                        contextMenuContainer,
                        locationInfo,
                        caseInfo: selectedCase,
                        onShowToastMessage: showToastMessage,
                        imageRequestHD,
                        currentPage,
                        setCurrentPage,
                        goToPageWithTargetImage, 
                        pageSize,
                        setPageSize,
                        currentViewImageIndex,
                        setCurrentViewImageIndex,
                        roiPopupImage,
                        setRoiPopupImage,
                        showRoiModal,
                        setShowRoiModal,
                        onHideRoiModal,
                        onShowRoiModal,
                        openLightBox,
                        images,
                        setImages,
                        getImageSrc,
                        imageSequenceNumberToShowInPopup,
                        setImageSequenceNumberToShowInPopup, 
                        onGoToImage,
                        setShowGoToImageLoadingAnim,
                        showGoToImageLoadingAnim
                    }}>
                    {
                        getImageGallery(galleryName)
                    }
                </CaseImagesContext.Provider>
            }

            <ConfirmDialogBox
                title="Auto-Select Images?"
                show={showAutoSelectConfirmation}
                onHide={toggleAutoSelectConfirmation}
                onConfirm={createAutoSelectJob}
                isLoading={isCreatingSelectJob}
                onCancel={toggleAutoSelectConfirmation}>
                <p>Do you want to auto select images?</p>
                {selectJob && selectJob.select_image_ids.length > 0 && <span
                    className="fw-bold text-danger"
                >NB: This will erase previous selections</span>}
            </ConfirmDialogBox>

            <ConfirmDialogBox
                title="Cancel Auto-Select ?"
                actionInProgress={isCancellingSelectJob}
                onHide={() => setShowCancelJobConfirmation(!showCancelJobConfirmation)}
                onCancel={() => setShowCancelJobConfirmation(!showCancelJobConfirmation)}
                onConfirm={() => cancelSelectJob()}
                confirmBtnVariant="danger"
                show={showCancelJobConfirmation}>
                <span>Are you sure you want to cancel this job?</span>
            </ConfirmDialogBox>

            <ToastContainer className="p-3 position-fixed bottom-0 start-0">
                {toastMessages.map((item: ToastNotification, index) => {
                    return (
                        <Toast
                            key={index}
                            onClose={() => onCloseToast(index)}
                            delay={5000}
                            autohide
                        >
                            <Toast.Header className={`bg-${item.type} text-white`}>
                                <span className="material-symbols-outlined">info</span>
                                <strong className="ms-2 me-auto">Notification Message</strong>
                            </Toast.Header>
                            <Toast.Body className="bg-white">{item.message}</Toast.Body>
                        </Toast>
                    );
                })}
            </ToastContainer>
        </React.Fragment>
    )
}

export default CaseImages
